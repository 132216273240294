import { useState, useEffect, useRef } from "react";
import { AddLayout } from "../../../layouts";
import { Button, Grid, TextField, DataGrid } from "../../../components";
import {
  ModeloTributacaoService,
  SituacaoTributariaIpiService,
  EnquadramentoLegalService,
  SituacaoPisService,
  SituacaoCofinsService,
  OperacaoFiscalService,
  GruposTributarioService,
  CfopService,
  notification,
  history,
  GruposTributarioModelosTributacaoService,
  EnderecoService,
  TipoDesoneracaoService,
  CsoSimplesService,
} from "../../../services";
import { InputErros, PermisoesHelper } from "../../../helpers";
import { responseErros, voltar } from "../../../utils";
import modeloTributacaoValidator from "./middlewares/modelo-tributacao.validator";
import { FormFiltroAvancado, ModeloTributacaoDialog } from "./components";
import "./add-modelo-tributacao.css";
import { useImportContext } from "../../../contexts/import.context";
import { getMultipleAutocompleteValues } from "./modelo-tributacao";

const modeloTributacaoService = new ModeloTributacaoService();
const grupoTributarioModeloTributacaoService =
  new GruposTributarioModelosTributacaoService();
const operacaoFiscalService = new OperacaoFiscalService();
const gruposTributarioService = new GruposTributarioService();
const cfopService = new CfopService();
const situacaoTributariaIpiService = new SituacaoTributariaIpiService();
const enquadramentoLegalService = new EnquadramentoLegalService();
const tipoDesoneracaoService = new TipoDesoneracaoService();
const csoSimplesService = new CsoSimplesService();
const situacaoPisService = new SituacaoPisService();
const situacaoCofinsService = new SituacaoCofinsService();
const enderecoService = new EnderecoService();

const AddModeloTributacaoView = ({ match }) => {
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("modelos-tributacao-visualizar");
  const { id } = match.params;
  const { initialCollapses } = useImportContext();
  const [loading, setLoading] = useState(false);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [abaValue, setAbaValue] = useState(0);
  const [icmsId, setIcmsId] = useState(null);
  const [modeloTributacao, setModeloTributacao] = useState([]);
  const [grupoModeloTributacao, setGrupoModeloTributacao] = useState({
    tipoOperacao: "SAIDA",
  });
  const [listaGrupoModeloTributacao, setListaGrupoModeloTributacao] = useState(
    []
  );
  const [situacaoTributariaIpiList, setSituacaoTributariaIpiList] = useState(
    []
  );
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const [grupoTributarioList, setGrupoTributarioList] = useState([]);
  const [enquadramentoLegalList, setEnquadramentoLegalList] = useState([]);
  const [tipoDesoneracaoList, setTipoDesoneracaoList] = useState([]);
  const [csoSimplesList, setCsoSimplesList] = useState([]);
  const [cfopList, setCfopList] = useState([]);
  const [situacaoPisList, setSituacaoPisList] = useState([]);
  const [situacaoCofinsList, setSituacaoCofinsList] = useState([]);
  const [openTributacaoDialog, setOpenTributacaoDialog] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const [estados, setEstados] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const cfopRef = useRef();

  const colunasModelo = [
    {
      field: "tipoOperacao",
      headerName: "Tipo Operação",
      flex: 140,
    },
    {
      field: "grupoTributarioId",
      headerName: "Grupo Tributário",
      flex: 350,
      valueGetter: (params) => {
        const grupoTributario = grupoTributarioList.find(
          (grupo) => grupo.id === params.value
        );
        if (grupoTributario) {
          return grupoTributario.nome;
        } else {
          return params.value;
        }
      },
    },
    {
      field: "estados",
      headerName: "UF Destino",
      flex: 300,
      valueGetter: (params) => {
        const listaEstados = params.value.map((estado) => {
          const estadoComSigla = estados.find(
            (item) => item.id == estado.estadoId
          );
          return estadoComSigla?.sigla;
        });
        if (params.value.length == 26) {
          return "Todos estados exceto origem";
        }
        return listaEstados.toString().replace(/,/g, ", ");
      },
    },
    {
      field: "operacoesFiscais",
      headerName: "Operação Fiscal",
      flex: 450,
      valueGetter: (params) => {
        const listaOperacaoFiscal = params.value.map((operacaoFiscal) => {
          const opEncontrada = operacaoFiscalList.find(
            (item) => item.id == operacaoFiscal.fisOperacaoFiscalId
          );
          return opEncontrada?.descricao;
        });
        return listaOperacaoFiscal.toString().replace(/,/g, ", ");
      },
    },
  ];

  async function buscarDadosIniciais() {
    await buscaModeloTributacao();
  }

  useEffect(() => {
    if (id) {
      buscarDadosIniciais();
      buscarRegrasModelo();
    }
    buscarOperacoesFiscais();
    buscarGruposTributarios();
    buscaSituacaoTributariaIpi();
    buscaEnquadramentoLegal();
    buscaTipoDesoneracao();
    buscaCsoSimples();
    buscaSituacaoPis();
    buscaEstados();
    buscaSituacaoCofins();
    buscaEstados();
  }, [id]);

  const buscaModeloTributacao = async () => {
    setLoading(true);
    const response = await modeloTributacaoService.getById(id);
    if (!response.isAxiosError) {
      if (!response.data) {
        initialCollapses();
        voltar();
        return;
      }
      setModeloTributacao((prevState) => ({
        ...prevState,
        descricao: response.data.descricao,
      }));
    } else {
      responseErros(response);
    }
    setLoading(false);
  };

  const buscarRelacionamentosModeloTributacao = (
    listaGrupoModeloTributacao
  ) => {
    const idsCfop = [];
    for (const tributacao of listaGrupoModeloTributacao) {
      idsCfop.push(tributacao.cfopId);
    }
    const idsUnicosCfop = [...new Set(idsCfop)];
    if (idsCfop.length > 0) {
      buscarCfops({}, idsUnicosCfop);
    }
  };

  const buscarOperacoesFiscais = (filtros) => {
    const filtro = {
      ...filtros,
      nonPaginated: true,
    };
    operacaoFiscalService.getAllFiltroAvancado(filtro).then((result) => {
      if (!result.isAxiosError) {
        setOperacaoFiscalList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarCfops = async (filtro, listaIds) => {
    const filtros = {
      ...filtro,
      limite: 20,
    };
    if (listaIds) filtros.id = listaIds;
    cfopService.getAll(filtros).then((result) => {
      setLoadingAutoComplete(false);
      if (!result.isAxiosError) {
        setCfopList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarGruposTributarios = () => {
    const filtro = {
      nonPaginated: true,
    };
    gruposTributarioService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setGrupoTributarioList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscaSituacaoTributariaIpi = () => {
    situacaoTributariaIpiService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setSituacaoTributariaIpiList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscaEnquadramentoLegal = () => {
    enquadramentoLegalService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setEnquadramentoLegalList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscaTipoDesoneracao = () => {
    tipoDesoneracaoService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setTipoDesoneracaoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscaCsoSimples = () => {
    csoSimplesService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setCsoSimplesList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscaSituacaoPis = () => {
    situacaoPisService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setSituacaoPisList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscaSituacaoCofins = () => {
    situacaoCofinsService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setSituacaoCofinsList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscaEstados = () => {
    enderecoService.getEstados().then((result) => {
      if (!result.isAxiosError) {
        setEstados([
          ...result.data,
          {
            id: "01",
            nome: "Todos estados exceto origem",
          },
        ]);
      } else {
        responseErros(result);
      }
    });
  };

  const handleInputChange = (e) => {
    setModeloTributacao((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOpenIcmsDialog = () => {
    setOpenTributacaoDialog(true);
    setIcmsId(null);
  };

  const handleEditarTributacao = async (id) => {
    const grupoModeloTributacaoTemp = listaGrupoModeloTributacao.find(
      (item) => item.id === id
    );
    const modeloIcmsTemp = {
      ...grupoModeloTributacaoTemp,
      operacoesFiscalId: getMultipleAutocompleteValues(
        grupoModeloTributacaoTemp.operacoesFiscais,
        operacaoFiscalList,
        "fisOperacaoFiscalId"
      ),
      estadosId: getMultipleAutocompleteValues(
        grupoModeloTributacaoTemp.estados,
        estados,
        "estadoId"
      ),
    };
    setGrupoModeloTributacao(modeloIcmsTemp);
    setIcmsId(id);
    setOpenTributacaoDialog(true);
  };

  const handleRemoverModeloTributacaoIcms = async (id) => {
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir esta regra de tributação?`
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await grupoTributarioModeloTributacaoService.deletar(id);
      if (!result.isAxiosError) {
        buscarRegrasModelo();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    modeloTributacaoValidator
      .validate(modeloTributacao, { abortEarly: false })
      .then(async () => {
        if (id) {
          const response = await modeloTributacaoService.atualizar(
            id,
            modeloTributacao
          );
          setLoading(false);
          if (!response.isAxiosError) {
            notification.alteracaoSucesso();
            history.goBack();
          } else {
            responseErros(response);
          }
        } else {
          if (listaGrupoModeloTributacao.length == 0) {
            return notification.alertaGenericos(
              "Adicione ao menos uma tributação."
            );
          }
          const modeloTributacaoTemp = {
            ...modeloTributacao,
            tributacao: listaGrupoModeloTributacao.map((obj) => {
              return {
                ...obj,
                operacoesFiscalId: obj.operacoesFiscais.map(
                  (item) => item.fisOperacaoFiscalId
                ),
                estadosId: obj.estados.map((item) => item.estadoId),
              };
            }),
          };
          const response = await modeloTributacaoService.cadastrar(
            modeloTributacaoTemp
          );
          setLoading(false);
          if (!response.isAxiosError) {
            notification.cadastroSucesso();
            history.goBack();
          } else {
            responseErros(response);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  const onSearchChangeCfop = (e, id) => {
    if (e.key === "Enter" && cfopRef.current === document.activeElement) {
      const eventValue = document.getElementById(id).value;
      let filtros = {
        codigo: eventValue,
        descricao: eventValue,
      };
      setLoadingAutoComplete(true);
      buscarCfops(filtros);
    }
  };

  const buscarRegrasModelo = async (filtro) => {
    if (id) {
      setLoading(true);
      const filtrosAvancadosTemp = {
        ...filtro,
        modeloTributacaoId: parseInt(id),
        nonPaginated: true,
        restritiva: true,
      };
      const result =
        await grupoTributarioModeloTributacaoService.getAllFiltroAvancado(
          filtrosAvancadosTemp
        );
      if (!result.isAxiosError) {
        setListaGrupoModeloTributacao(result.data);
        if (result.data.length) {
          buscarRelacionamentosModeloTributacao(result.data);
        }
      } else {
        responseErros(result);
      }
      setLoading(false);
    } else {
      const filtrosAvancadosTemp = {
        ...filtro,
        nonPaginated: true,
        restritiva: true,
      };
      for (const key in filtrosAvancadosTemp) {
        if (filtrosAvancadosTemp[key] == null) {
          delete filtrosAvancadosTemp[key];
        }
      }
    }
  };

  const sendServerDatagrid = (props) => {
    if (id) {
      buscarRegrasModelo(props.filtros);
    }
  };

  return (
    <AddLayout
      id={id}
      title={
        id ? "Editar Modelo de Tributação" : "Cadastrar Modelo de Tributação"
      }
      showUpdate={permissoesHelper.temPermisao("modelos-tributacao-editar")}
      onClickSalvar={handleSubmit}
      loading={loading}
    >
      <Grid container spacing={2} className="d-flex justify-content-between">
        <Grid item xs={8}>
          <TextField
            id="descricao"
            name="descricao"
            label="Descrição"
            variant="outlined"
            margin="normal"
            value={modeloTributacao?.descricao ?? ""}
            onChange={handleInputChange}
            fullWidth
            required
            error={inputErros.get("descricao")}
          />
        </Grid>
        <Grid
          item
          xs={3}
          className="d-flex justify-content-end align-items-center"
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleOpenIcmsDialog}
            disabled={
              !permissoesHelper.temPermisao("modelos-tributacao-cadastrar")
            }
          >
            Adicionar regra
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-4">
        <DataGrid
          rows={listaGrupoModeloTributacao}
          columns={colunasModelo}
          editShow
          onClickEdit={handleEditarTributacao}
          deleteShow
          onClickDelete={handleRemoverModeloTributacaoIcms}
          paginationMode="server"
          FormFiltroAvancado={FormFiltroAvancado}
          sendServer={sendServerDatagrid}
        />
      </Grid>
      <ModeloTributacaoDialog
        modeloTributacaoId={id}
        cfopRef={cfopRef}
        abaValue={abaValue}
        setAbaValue={setAbaValue}
        open={openTributacaoDialog}
        setOpen={setOpenTributacaoDialog}
        loadingAutoComplete={loadingAutoComplete}
        setloadingAutoComplete={setLoadingAutoComplete}
        grupoModeloTributacao={grupoModeloTributacao}
        setGrupoModeloTributacao={setGrupoModeloTributacao}
        buscarOperacoesFiscais={buscarOperacoesFiscais}
        listaGrupoModeloTributacao={listaGrupoModeloTributacao}
        setListaGrupoModeloTributacao={setListaGrupoModeloTributacao}
        csoSimplesList={csoSimplesList}
        tipoDesoneracaoList={tipoDesoneracaoList}
        estados={estados}
        operacaoFiscalList={operacaoFiscalList}
        grupoTributarioList={grupoTributarioList}
        cfopList={cfopList}
        onSearchChangeCfop={onSearchChangeCfop}
        listaModeloTributacaoIcms={listaGrupoModeloTributacao}
        setListaModeloTributacaoIcms={setListaGrupoModeloTributacao}
        icmsId={icmsId}
        setIcmsId={setIcmsId}
        situacaoTributariaIpiList={situacaoTributariaIpiList}
        enquadramentoLegalList={enquadramentoLegalList}
        situacaoPisList={situacaoPisList}
        situacaoCofinsList={situacaoCofinsList}
        buscarRegrasModelo={buscarRegrasModelo}
        grupoTributarioModeloTributacaoService={
          grupoTributarioModeloTributacaoService
        }
      />
    </AddLayout>
  );
};

export default AddModeloTributacaoView;
