import { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Paper,
  DatePicker,
  Autocomplete,
  DataGrid,
  MenuCustom,
  Loading,
  IconButton,
} from "../../components";
import { ButtonMenu, MenuAddActions } from "./components";
import { formatDate, formatPrice, responseErros } from "../../utils";
import { value, InputErros, PermisoesHelper } from "../../helpers";
import {
  BancosCaixasResponsaveisService,
  BancosCaixasService,
  CaixaService,
  notification,
  PessoaService,
  SessionService,
  MeioPagamentoService,
} from "../../services";
import { caixaValidator } from "./middlewares";
import "./caixa-style.scss";

const Caixa = () => {
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("caixa-visualizar");
  const [listaCaixas, setListasCaixas] = useState([]);
  const [listaPessoas, setListaPessoas] = useState([]);
  const [listaMeiosPagamentos, setListaMeiosPagamentos] = useState([]);
  const [totaisCaixa, setTotaisCaixa] = useState({});
  const [formCaixa, setFormCaixa] = useState({
    data: new Date(),
  });
  const [transferenciaCaixa, setTransferenciaCaixa] = useState({
    dataMovimentacao: new Date(),
  });
  const [caixaAberto, setCaixaAberto] = useState(null);
  const [acessoCaixa, setAcessoCaixa] = useState(false);
  const [contaLista, setContaLista] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-add-actions-menu";

  const bancosCaixasService = new BancosCaixasService();
  const pessoaService = new PessoaService();
  const caixaService = new CaixaService();
  const sessionService = new SessionService();
  const meioPagamentoService = new MeioPagamentoService();
  const bancosCaixasResponsaveisService = new BancosCaixasResponsaveisService();
  const inputErros = new InputErros(inputErro, setInputErro);
  const columns = [
    {
      field: "categoriaMovimentacao",
      headerName: "Tipo",
      flex: 190,
      renderCell: (params) => {
        switch (params.value) {
          case "SUPRIMENTO":
            return tipoCategoriaMovimentacao("#4FBB53", "Suprimento");
          case "SANGRIA":
            return tipoCategoriaMovimentacao("#DC3545", "Sangria");
          case "TRANSFERENCIA":
            return tipoCategoriaMovimentacao(
              params.row?.tipo === "ENTRADA" ? "#4FBB53" : "#DC3545",
              "Transferência"
            );
          default:
            return tipoCategoriaMovimentacao(
              params.row?.modelo === "RECEITA" ? "#4FBB53" : "#DC3545",
              "Título Normal"
            );
        }
      },
    },
    {
      field: "numeroTitulo",
      headerName: "Nº Documento",
      flex: 250,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "clienteFornecedor",
      headerName: "Cliente/Fornecedor",
      flex: 300,
      hideSortIcons: true,
      sortable: false,
      valueGetter: (params) =>
        listaPessoas.find((item) => item.id == params.value)?.nomeRazaoSocial ??
        "",
    },
    {
      field: "valor",
      headerName: "Valor R$",
      flex: 250,
      hideSortIcons: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.tipo === "NOTA_CREDITO" ||
        (params.row.tipo === "TITULO_NORMAL" && params.row.modelo === "DESPESA")
          ? `- ${formatPrice(params.value)}`
          : formatPrice(params.value),
    },
    {
      field: "fatMeioPagamentoId",
      headerName: "Meio Pagamento",
      flex: 250,
      hideSortIcons: true,
      sortable: false,
      valueGetter: (params) =>
        listaMeiosPagamentos.find((item) => item.id == params.value)
          ?.descricao ?? "",
    },
    {
      field: "saldo",
      headerName: "Saldo",
      flex: 250,
      hideSortIcons: true,
      sortable: false,
      valueGetter: (params) => formatPrice(params.value),
    },
  ];

  const tipoCategoriaMovimentacao = (color, text) => {
    return (
      <>
        <span className="d-flex justify-content-center align-items-center">
          <span className="me-2">
            <i className="ph-fill ph-circle" style={{ color }}></i>
          </span>
          <span>{text}</span>
        </span>
      </>
    );
  };

  const handleAddActionsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    buscarBancoCaixaResponsavel();
  }, []);

  const buscarBancoCaixaResponsavel = async () => {
    setLoading(true);
    const user = sessionService.getUsuario();
    const result = await bancosCaixasResponsaveisService.getAll({
      usuarioId: user.id,
      nonPaginated: true,
    });
    if (!result.isAxiosError) {
      const arrayData = result.data.map((bancosIds) => bancosIds.bancoCaixaId);
      if (arrayData?.length) {
        buscaContas(arrayData);
      } else {
        setAcessoCaixa(true);
        notification.alertaGenericos(
          "Você não tem acesso a nenhum caixa. Entre em contato com o administrador do sistema"
        );
      }
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscaContas = (data) => {
    const filtro = {
      nonPaginated: true,
      id: data,
    };
    bancosCaixasService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setContaLista(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const validarStatusCaixa = (formCaixa) => {
    caixaService
      .caixaAberto(formCaixa.data, formCaixa.bancoCaixaId)
      .then((result) => {
        if (!result.isAxiosError) {
          setCaixaAberto(result.data);
        } else {
          responseErros(result);
        }
      });
  };

  const buscarCaixa = async (formCaixa, filter) => {
    setLoading(true);
    const result = await caixaService.getRelatorios(
      formCaixa.data,
      formCaixa.bancoCaixaId,
      { ...filter, orderBy: "dataPagamento", order: "ASC" }
    );
    if (!result.isAxiosError) {
      validarStatusCaixa(formCaixa);
      setListasCaixas(result.data.rows.reverse());
      setTotaisCaixa(result.data.totaisCaixa);
      if (result.data.rows.length) {
        buscarPessoas(result.data.rows);
        buscarMeiosPagamentos(result.data.rows);
      }
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarMeiosPagamentos = (listaCaixas) => {
    const listaIds = [
      ...new Set(listaCaixas.map((item) => item.fatMeioPagamentoId)),
    ];
    if (listaIds.length > 0) {
      const filtro = {
        id: listaIds,
        nonPaginated: true,
      };
      meioPagamentoService.getAll(filtro).then((result) => {
        if (!result.isAxiosError) {
          setListaMeiosPagamentos(result.data);
        } else {
          responseErros(result);
        }
      });
    }
  };

  const buscarPessoas = (listaCaixas) => {
    const listaIds = listaCaixas
      .filter((item) => item.clienteFornecedor)
      .map((item) => item.clienteFornecedor);
    if (listaIds.length > 0) {
      const filtro = {
        id: listaIds,
        nonPaginated: true,
      };
      pessoaService.getAll(filtro).then((result) => {
        if (!result.isAxiosError) {
          setListaPessoas(result.data);
        } else {
          responseErros(result);
        }
      });
    }
  };

  const onChangeDate = (name, date) => {
    if (!date) {
      date = "Invalid Date";
    }
    const formCaixaTemp = {
      ...formCaixa,
      [name]: date != "Invalid Date" ? formatDate.toSend(date) : "",
    };
    setFormCaixa(formCaixaTemp);
    if (formCaixaTemp.data && formCaixa.bancoCaixaId) {
      buscarCaixa(formCaixaTemp);
    }
  };

  const buscarConta = (name, value) => {
    const formCaixaTemp = {
      ...formCaixa,
      [name]: value ? value.id : undefined,
    };
    setFormCaixa(formCaixaTemp);
    if (formCaixa.data && formCaixaTemp.bancoCaixaId) {
      buscarCaixa(formCaixaTemp);
    }
  };

  const onClickCadastrarCaixa = async (aberto) => {
    caixaValidator
      .validate(formCaixa, { abortEarly: false })
      .then(() => {
        cadastrarCaixa({ ...formCaixa, aberto });
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const cadastrarCaixa = async (formCaixa) => {
    const result = await caixaService.cadastrar(formCaixa);
    if (!result.isAxiosError) {
      notification.alteracaoSucesso();
      buscarCaixa(formCaixa);
    } else {
      responseErros(result);
    }
  };

  return (
    <Container>
      <MenuCustom
        codigo={
          <IconButton
            aria-label="add action caixa"
            aria-controls={menuId}
            aria-haspopup="true"
            aria-expanded={isMenuOpen ? "true" : undefined}
            onClick={handleAddActionsOpen}
            color="inherit"
            style={{
              borderRadius: 5,
              backgroundColor: "#E5E5E5",
            }}
          >
            <i className="ph-fill ph-plus-square" style={{ fontSize: 23 }}></i>
          </IconButton>
        }
        ButtonMenu={
          <ButtonMenu
            onClickCadastrarCaixa={onClickCadastrarCaixa}
            caixaAberto={caixaAberto}
          />
        }
      ></MenuCustom>
      <Card>
        <CardHeader
          title="Caixa"
          action={
            <>
              {caixaAberto !== null && (
                <span
                  className={`badge ${
                    caixaAberto ? "bg-success" : "bg-danger"
                  } text-wrap p-2 me-3`}
                >
                  {caixaAberto ? "ABERTO" : "FECHADO"}
                </span>
              )}
            </>
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <DatePicker
                id="data"
                name="data"
                label="Data"
                format="dd/MM/yyyy"
                margin
                required
                error={inputErros.get("data")}
                value={value.date(formCaixa.data)}
                onChange={(date) => onChangeDate("data", date)}
              />
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                id="bancoCaixaId"
                name="bancoCaixaId"
                options={contaLista}
                noOptionsText="Sem opções"
                autoHighlight
                getOptionLabel={(option) => (option.nome ? option.nome : "")}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Conta"
                    margin="normal"
                    required
                    variant="outlined"
                    error={inputErros.get("bancoCaixaId")}
                  />
                )}
                onChange={(e, value) => buscarConta("bancoCaixaId", value)}
                value={value.autoComplete(contaLista, formCaixa.bancoCaixaId)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="mt-3">
            <Grid item xs={4}>
              <Paper
                id="totalReceita"
                className="py-3 px-2 text-black text-center border"
              >
                Total de Entradas
                <br />
                <span className="fs-4">
                  {formatPrice(totaisCaixa.totalEntradas) || "R$ 0,00"}
                </span>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                id="totalDespesa"
                className="py-3 px-2 text-black text-center border"
              >
                Total de Saídas
                <br />
                <span className="fs-4">
                  {formatPrice(totaisCaixa.totalSaidas) || "R$ 0,00"}
                </span>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                id="totalSaldo"
                className="py-3 px-2 text-black text-center border"
              >
                Saldo do Dia
                <br />
                <span className="fs-4">
                  {formatPrice(totaisCaixa.saldoDia) || "R$ 0,00"}
                </span>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3} className="mt-4">
            <Grid item xs={12}>
              <DataGrid
                rows={listaCaixas}
                columns={columns}
                paginationMode="client"
                noFilter
                disableAcoes
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <MenuAddActions
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        acessoCaixa={acessoCaixa}
        listaContasUsuario={contaLista}
        listaCaixas={listaCaixas}
        totaisCaixa={totaisCaixa}
        formCaixa={formCaixa}
        setFormCaixa={setFormCaixa}
        transferenciaCaixa={transferenciaCaixa}
        setTransferenciaCaixa={setTransferenciaCaixa}
        setTotaisCaixa={setTotaisCaixa}
        buscarCaixa={buscarCaixa}
      />
      <Loading loading={loading} />
    </Container>
  );
};

export default Caixa;
