import shortid from "shortid";
import {
  calculaSubtotalPagamentos,
  calculaSubtotalParaChave,
  calculaSubtotalProdutos,
  calculaSubtotalTributos,
  totalCompra,
} from "../add-nf-entrada/add-nf-entrada";
export class NfEntrada {
  operacaoFiscalId = null;
  operacaoFiscal = null;
  fornecedorId = null;
  transportadoraId = null;
  cnpj = null;
  modelo = null;
  numeroNota = null;
  serie = null;
  dataEmissao = new Date();
  dataChegada = new Date();
  finalidade = null;
  modalidadeFrete = null;
  freteCompoeNota = null;
  nfeChave = null;
  informacoes = null;
  subtotalProdutos = null;
  subtotalTributos = null;
  subtotalDesconto = null;
  subtotalDescontoPorcentagem = null;
  subtotalAcrescimo = null;
  subtotalDespesas = null;
  frete = null;
  baixaAutomatica = null;
  usoConsumo = null;
  bancoCaixaId = null;
  total = 0;
  totalPagamentos = 0;
  entrada = 0;
  freteCompoeNota = null;
  nfEntradaTributos = {};
  nfEntradaPagamentos = [];
  nfEntradaItens = [];
  createdAt = null;
  updatedAt = null;
  insertUserId = null;

  constructor(nfEntrada, itens, tributos, pagamentos, frete) {
    this.createdAt = nfEntrada.createdAt;
    this.updatedAt = nfEntrada.updatedAt;
    this.insertUserId = nfEntrada.insertUserId;
    this.operacaoFiscalId = nfEntrada.operacaoFiscalId;
    this.operacaoFiscal = nfEntrada.operacaoFiscal;
    this.fornecedorId = nfEntrada.fornecedorId;
    this.transportadoraId = nfEntrada.transportadoraId
      ? nfEntrada.transportadoraId
      : null;
    this.cnpj = nfEntrada.cnpj;
    this.modelo = nfEntrada.modelo;
    this.numeroNota = nfEntrada.numeroNota
      ? nfEntrada.numeroNota.replace(/[^0-9]+/, "")
      : null;
    this.serie = nfEntrada.serie;
    this.dataEmissao = nfEntrada.dataEmissao;
    this.dataChegada = nfEntrada.dataChegada;
    this.finalidade = nfEntrada.finalidade;
    this.modalidadeFrete = nfEntrada.modalidadeFrete;
    this.freteCompoeNota = nfEntrada.freteCompoeNota;
    this.nfeChave = nfEntrada.nfeChave;
    this.informacoes = nfEntrada.informacoes;
    this.subtotalProdutos = calculaSubtotalProdutos(
      itens ?? nfEntrada.nfEntradaItens
    );
    this.subtotalTributos = calculaSubtotalTributos(
      tributos ?? nfEntrada.nfEntradaTributos
    );
    this.subtotalDesconto = calculaSubtotalParaChave(
      itens ?? nfEntrada.nfEntradaItens,
      "",
      "desconto"
    );
    this.subtotalAcrescimo = calculaSubtotalParaChave(
      itens ?? nfEntrada.nfEntradaItens,
      "",
      "acrescimo"
    );
    this.subtotalDespesas = calculaSubtotalParaChave(
      itens ?? nfEntrada.nfEntradaItens,
      "",
      "despesas"
    );
    this.frete = frete ?? nfEntrada?.frete;
    this.baixaAutomatica = nfEntrada.baixaAutomatica;
    this.usoConsumo =
      nfEntrada.usoConsumo === true || nfEntrada.usoConsumoImportado === true
        ? true
        : false;
    this.bancoCaixaId = nfEntrada.bancoCaixaId;
    this.totalCompra = totalCompra(
      nfEntrada,
      itens ?? nfEntrada.nfEntradaItens,
      tributos ?? nfEntrada.nfEntradaTributos
    );
    this.totalPagamentos = calculaSubtotalPagamentos(
      pagamentos ?? nfEntrada.nfEntradaPagamentos
    );
    this.subtotalDescontoPorcentagem = nfEntrada.subtotalDescontoPorcentagem;
    this.nfEntradaTributos = tributos ? tributos : nfEntrada?.nfEntradaTributos;
    this.nfEntradaPagamentos = pagamentos
      ? pagamentos
      : nfEntrada?.nfEntradaPagamentos;
    this.nfEntradaItens = itens
      ? itens
      : nfEntrada?.nfEntradaItens.map((item) =>
          item?.id ? item : { ...item, id: shortid.generate() }
        );
  }
}
