import { FormatCfopHelper } from "../../../helpers";
import { getOrigem } from "../../nf-entrada/list-nf-entrada/nf-entrada-xml";
const cfopHelper = new FormatCfopHelper();

export class FormatXmlProdutos {
  constructor(
    itens,
    fornecedorId,
    listaNcm,
    listaCfops,
    listaUnidadesComerciais,
    origensMercadoriasList,
    nfEntradaDto
  ) {
    const cfopTemp =
      listaCfops.length &&
      !nfEntradaDto?.usoConsumo &&
      listaCfops.find(
        ({ codigo }) => codigo == cfopHelper.contraPartida(itens.CFOP)
      );
    const ncmTemp = listaNcm.find(({ codigo }) => codigo == itens.NCM);
    const unidade = listaUnidadesComerciais.find((unidadeComercial) =>
      unidadeComercial.codigo.toLowerCase().includes(itens.uCom.toLowerCase())
    );
    const origemNacionalId = origensMercadoriasList.find(
      (origem) => origem.codigo == 0
    )?.id;
    this.tipo = "simples";
    this.ordem = itens.nItem;
    this.cean =
      itens.cEAN === "SEM GTIN" || typeof itens.cEAN === "object"
        ? null
        : itens.cEAN;
    this.codigoBarra =
      itens.cEAN === "SEM GTIN" || typeof itens.cEAN === "object"
        ? null
        : itens.cEAN;
    this.cfop = cfopTemp ? cfopHelper.contraPartida(itens.CFOP) : "";
    this.cfopId = cfopTemp?.id ? cfopTemp.id : null;
    this.produto = itens.xProd.substring(0, 50);
    this.descricao = itens.xProd.substring(0, 50);
    this.fornecedorId = fornecedorId;
    this.unidadeComercialCompraId = unidade?.id ? unidade.id : null;
    this.unidadeComercialVendaId = unidade?.id ? unidade.id : null;
    this.unidade = unidade?.codigo ? unidade.codigo : null;
    this.codigoProduto = itens.cProd;
    this.quantidadeCompra = parseFloat(String(itens.qCom).replace(".", ","));
    this.quantidadeEmbalagem = parseFloat(String(itens.qCom).replace(".", ","));
    this.quantidade = parseFloat(String(itens.qCom).replace(".", ","));
    this.precoCompra = parseFloat(itens.vUnCom).toFixed(2);
    this.precoCusto = parseFloat(itens.vUnCom).toFixed(2);
    this.precoVenda = parseFloat(itens.vUnCom).toFixed(2);
    this.subtotal = parseFloat(itens.vProd);
    this.origemMercadoriaId =
      getOrigem(itens.ICMS, origensMercadoriasList)?.id || origemNacionalId;
    this.tributosIcmsOrigem =
      getOrigem(itens.ICMS, origensMercadoriasList)?.id || origemNacionalId;
    this.ncmCodigo = ncmTemp?.codigo;
    this.ncm = ncmTemp?.codigo;
    this.ncmId = ncmTemp?.id;
    this.informacaoAdicionalNfe = itens.informacaoAdicionalNfe;
    this.tributosIcmsAliquota = itens?.ICMS?.pICMS
      ? parseFloat(itens.ICMS.pICMS)
      : 0;
    this.tributosValorIcmsAliquota = itens?.ICMS?.vICMS
      ? parseFloat(itens.ICMS.vICMS)
      : 0;
    this.impostoIcmsStAliquota = itens.ICMS.pICMSST
      ? parseFloat(itens.ICMS.pICMSST)
      : 0;
    this.impostoValorIcmsStAliquota = itens?.ICMS?.vICMSST
      ? parseFloat(itens.ICMS.vICMSST)
      : 0;
    this.tributosIpiAliquota = itens?.IPI?.pIPI
      ? parseFloat(itens.IPI.pIPI)
      : 0;
    this.tributosValorIpiAliquota = itens?.IPI?.vIPI
      ? parseFloat(itens.IPI.vIPI)
      : 0;
    this.aliquotaMva = itens?.ICMS?.pMVAST ? parseFloat(itens.ICMS.pMVAST) : 0;
    this.valorFrete = itens?.vFrete ? parseFloat(itens.vFrete) : null;
  }
}
